export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn €50"])}
  },
  "recommendation-form": {
    "affiliate-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy affiliate link"])},
    "affiliate-link-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate link has been copied"])},
    "register-another-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register another company"])},
    "recommendation-success": {
      "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your recommendation!"])},
      "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will process it immediately!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your recommendaton"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of business"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you! Your recommendation has been sent."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive a €50 bonus for successfully recommending Tippie. Recommend us to another establishment - as soon as the first tip is collected there, you will receive the bonus."])}
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "passport-upload": {
    "front-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front side"])},
    "back-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back side"])},
    "address-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address confirmation document"])},
    "upload-window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here or click to upload"])},
    "upload-window-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate password"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By logging in you accept the"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful registration"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creating an account, you accept our"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can’t seem to find the page you are looking for."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
    "open-payment-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open payment page"])},
    "download-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to homepage"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to disconnect from company ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been approved"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been assigned"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been changed"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been created"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been deleted"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry, we can't change ", _interpolate(_named("item"))])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry, we can't create ", _interpolate(_named("item"))])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " not found"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " has been unassigned"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer started"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassign"])},
    "file-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded"])},
    "file-not-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File not uploaded"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All items should be populated"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatar picture must be ", _interpolate(_named("format")), " format"])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatar picture size can not exceed ", _interpolate(_named("size"))])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " doesn't match"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " can't be greater than ", _interpolate(_named("max"))])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid ", _interpolate(_named("field")), " format"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Incorrect ", _interpolate(_named("field"))])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please input the ", _interpolate(_named("field")), " again"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " must be a number"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one item should be default"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one field should be filled"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " is required"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residual amount must be 0"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Payment Methods"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentation Mode"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie pays fees if guest does not pay"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiter always pays fee"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " service charge ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Help ", _interpolate(_named("nickname")), " get your tip in full"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We charge a small transaction fee because there are costs involved in processing. By contributing to cover these costs as part of your tip, you ensure that the tip recipient receives the full amount."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "organization": {
    "further-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the PayPal and Klarna button in the \"Further payment methods\" button"])},
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeback"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to unassign?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charity"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hairdresser"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide PayPal button if payer has Apple Pay or Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE ID"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create organization"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected tip amount"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected percent tip from receipt amount"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit organization"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable feedback/evaluation"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Review enabled"])}
    },
    "companyAvatarInsteadOfUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use company avatar instead of user avatar for pooling team"])},
    "custom-review": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Review Page"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Picture must be ", _interpolate(_named("format")), " format"])},
      "size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Picture size can not exceed ", _interpolate(_named("size"))])}
    },
    "new-design": {
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Design Payment Page"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Design Admin Panel"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Organizations"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Fee %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-selected tip"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr Code Ranges"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative contact"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send payment confirmation email?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organization"]), _normalize(["Organizations"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial Period End"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tripadvisor Review enabled"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic card"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stickers"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of IT system"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of sharing tips"])}
    },
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    },
    "apply-pay-pal-fixed-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply PayPal fixed fee"])},
    "not-apply-typical-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not apply typical pricing"])}
  },
  "pay-out": {
    "maintenance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability of the \"Payout\" function"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, this function is not available for technical reasons. We are working at full speed to eliminate the cause of the error and apologize for the inconvenience. The function to receive tips is still available without restrictions."])}
    },
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you can make a payment to your bank account, you must enter the account details. To do this, go to \"Edit profile\" in the app."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that your bank statement will show the sender of the money as <b>\"GRATUITY SYSTEMS INTERNATIONAL PTE. LTD.\"</b>, <b>\"tippie\"</b> or <b>\"Stripe\"</b> or <b>\"NaudaPay Limited\"</b>."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The transfer fee for this transaction is ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No fee is charged for withdrawals of ", _interpolate(_named("payoutFeeThreshold")), " or more."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay out automatically at threshold"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay out"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer to IBAN"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equal to all"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equal to remain"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error! The transaction could not be carried out. Please contact Tippie support"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percents"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residual amount"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residual percents"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REconciliation Srategy"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive a confirmation by e-mail within 24 hours. Please do not make any new withdrawals in the meantime."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pay"])},
    "to-reconcile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to be reconciled"])}
  },
  "profile": {
    "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR Code"])},
    "upload-avatar-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to upload"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignments"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance of your account:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips received during the last 7 days"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips received today"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount for transfer to bank account:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to IBAN"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your balance"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips transferred to your bank account:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips in transition to your bank account:"])}
    },
    "user-type": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User type"])},
      "types": {
        "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business owner / manager"])}
      }
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Image"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your IBAN is connected"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact to the support for resolving issue with IBAN"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivation"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send notifications to email?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number and one special character."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat New Password"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send confirmation of tip via SMS"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable automatic payout"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show employee reports"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User role"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "disable-uploading-avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable uploading avatar"])},
    "disable-unassigning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable unassigning"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we could not confirm your assignment"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create QR Code"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not assigned"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting confirmation"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been unassigned"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try modifying your search or select another report"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's nothing here yet"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last week"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last year"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max amount"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
    },
    "pooling-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling payout"])},
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Organisations"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outlier Payments"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Employees"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save report"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Save as ", _interpolate(_named("format"))])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Report"]), _normalize(["Reports"])])},
    "teams-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "team-pooling-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Pooling Results"])},
    "registration-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
    "distributed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributed"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "account-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account statement"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "team-lead-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Lead"])},
    "recipient-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID-OUT"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Team"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Team"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit team"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employee"]), _normalize(["Employees"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group QR Code"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible contact"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team with name ", _interpolate(_named("name")), " already exists"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Team"]), _normalize(["Teams"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show team name instead of username on the payment page."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deactivate the PayPal (and Klarna) button when tips are less ", _interpolate(_named("threshold")), " EUR."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been unassigned"])},
    "hideOnSelectTeamPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide a team on the Select Team Page for payment."])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay out"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search organizations, teams, QR code"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["Users"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Teams"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "reset-cache": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New content available"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the free app"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Leipzig, Germany"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". All Rights Reserved."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup link has been copied successfully!"])}
  },
  "uploader": {
    "upload-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop excel file here or"])},
    "click-to-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click to upload"])},
    "enter-manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or enter manually"])}
  }
}