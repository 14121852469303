export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundendienst"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen"])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdiene 50€"])}
  },
  "recommendation-form": {
    "affilate-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate-Link kopieren"])},
    "affilate-link-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partnerlink wurde kopiert"])},
    "register-another-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein anderes Unternehmen anmelden"])},
    "recommendation-success": {
      "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Empfehlung!"])},
      "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden sie sofort bearbeiten!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Empfehlung"])},
    "contact_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktperson"])},
    "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Geschäft"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke schön! Ihre Empfehlung wurde gesendet."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50€ Prämie erhältst für eine erfolgreiche Weiterempfehlung von Tippie. Empfehle uns bei einem anderen Betrieb weiter - sobald dort das erste Trinkgeld eingenommen wird, erhältst du die Prämie."])}
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "passport-upload": {
    "front-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorderseite"])},
    "back-side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückseite"])},
    "address-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument zur Bestätigung der Adresse"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erzeugen"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Anmeldung akzeptieren Sie"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du wirklich ausloggen?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Registrierung"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Erstellung eines Benutzerkontos akzeptieren Sie unsere"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unsere Geschäftsbedingungen"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können die Seite, die Sie suchen, nicht finden."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnen"])},
    "open-payment-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsseite öffnen"])},
    "download-qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR herunterladen"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie sich wirklich vom Unternehmen trennen ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " wurde genehmigt"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " wurde zugewiesen"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " wurde geändert"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " wurde erstellt"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " wurde gelöscht"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tut mir leid, wir können das ", _interpolate(_named("field")), " nicht ändern"])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tut uns leid, wir können kein ", _interpolate(_named("item")), " erstellen"])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " nicht gefunden"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Zuweisung des ", _interpolate(_named("item")), " wurde aufgehoben"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schicken"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups! Irgendwas lief schief!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung gestartet"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisung aufheben"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Elemente sollten ausgefüllt werden"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatarbild muss ", _interpolate(_named("format")), " sein"])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bildgröße kann ", _interpolate(_named("size")), " nicht überschreiten"])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " stimmt nicht überein"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der ", _interpolate(_named("field")), " darf nicht größer als ", _interpolate(_named("max")), " sein"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültiges ", _interpolate(_named("field")), " format"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Falsch ", _interpolate(_named("field"))])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte geben Sie das ", _interpolate(_named("field")), " erneut ein"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " muss eine Zahl sein"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Element sollte Standard sein"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Feld sollte ausgefüllt werden"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " ist erforderlich"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Restbetrag muss Null sein."])}
    },
    "file-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochgeladen"])},
    "file-not-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei nicht hochgeladen"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Zahlungsmethoden"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsentationsmodus"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie übernimmt die Kosten wenn der Gast sie nicht übernehmen will"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation zahlt immer Gebühr"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Gebühren ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hilf ", _interpolate(_named("nickname")), " dein Trinkgeld in voller Höhe zu erhalten"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir erheben eine geringe Transaktionsgebühr, denn es entstehen bei der Verarbeitung Kosten. Indem Sie zur Deckung dieser Kosten als Teil Ihres Trinkgeldes beitragen, stellen Sie sicher, dass der Trinkgeldempfänger den vollen Betrag erhält."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
  },
  "organization": {
    "further-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blenden Sie im Button \"Weitere Zahlungsarten\" den PayPal- und Klarna-Button aus"])},
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückmeldung"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Zuweisung wirklich aufheben?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohltätigkeit"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friseur"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal-Schaltfläche ausblenden, wenn der Zahler Apple Pay oder Google Pay hat"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensschlüssel"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen hinzufügen"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorausgewählter Trinkgeldbetrag"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgewählter Prozentsatz des Trinkgeldes vom Bonbetrag"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie löschen möchten?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen bearbeiten"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen/Kundenfeedback zulassen"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Bewertung aktiviert"])}
    },
    "companyAvatarInsteadOfUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie den Unternehmens-Avatar anstelle des Benutzer-Avatars für die Zusammenlegung des Teams"])},
    "custom-review": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Bewertungsseite"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Image muss ", _interpolate(_named("format")), " sein"])},
      "size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bildgröße kann ", _interpolate(_named("size")), " nicht überschreiten"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Unternehmen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicegebühr %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinkgeld Vorauswahl"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code Bereiche"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleg für Trinkgeld senden?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Unternehmen"]), _normalize(["Unternehmen"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Probezeit"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tripadvisor Bewertung aktiviert"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Kassensystem erzeugt"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufkleber"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des IT Systems"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuell"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaftlich"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinkgeld System"])}
    },
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    },
    "apply-pay-pal-fixed-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal-Festbetrag anwenden"])},
    "not-apply-typical-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typische Preise nicht anwenden"])}
  },
  "pay-out": {
    "maintenance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit der Funktion \"Auszahlung\""])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell steht diese Funktion aus technischen Gründen nicht zur Verfügung. Wir arbeiten mit Hochdruck an der Behebung der Fehlerursache und bitten für die Unannehmlichkeiten um Entschuldigung. Die Funktion, Trinkgeld zu erhalten, ist weiter uneingeschränkt verfügbar."])}
    },
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor du eine Auszahlung auf dein Bankkonto vornehmen kannst, musst du die Kontodaten hinterlegen. Gehe hierfür in der App auf \"Profil bearbeiten\"."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass auf deinem Kontoauszug der Absender des Geldes als <b>\"GRATUITY SYSTEMS INTERNATIONAL PTE. LTD.\"</b>, <b>\"tippie\"</b> oder <b>\"Stripe\"</b> oder <b>\"NaudaPay Limited\"</b> angezeigt wird."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Überweisungsgebühr für diese Transaktion beträgt ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ab ", _interpolate(_named("payoutFeeThreshold")), " Auszahlungsbetrag wird keine Gebühr erhoben."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Auszahlung bei Erreichen des Schwellenwerts"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung bestätigen"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleicher Betrag für alle"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleicher Betrag für verbleibende Mitarbeiter"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler! Die Überweisung konnte nicht ausgeführt werden. Bitte kontaktieren Sie den Tippie Support. "])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozent"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Betrag"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restprozentsatz"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling Strategie"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplex"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innerhalb von 24h erhältst du eine Bestätigung via E-Mail. Bitte führe in der Zwischenzeit keine neue Auszahlung aus."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
    "to-reconcile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszuzahlender Betrag"])}
  },
  "profile": {
    "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code zeigen"])},
    "upload-avatar-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier zum Hochladen"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnungen"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontostand:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltene Trinkgelder in den letzten 7 Tagen"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute erhaltene Trinkgelder"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag zur Überweisung an Bank:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung auf IBAN Konto"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Kontostand"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Ihr Bankkonto überwiesene Trinkgelder:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinkgelder auf dem Weg zum Bankkonto:"])}
    },
    "user-type": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzertyp"])},
      "types": {
        "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])}
      }
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild ändern"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre IBAN ist verbunden"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenden Sie sich an den Support, um das Problem mit der IBAN zu lösen"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivation"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Kennwort"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung per Email senden?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen enthalten, sowie mindestens ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl und ein Sonderzeichen."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederhole das neue Passwort"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinkgeldempfang via SMS bestätigen"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Auszahlung deaktivieren"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-Rolle"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterberichte anzeigen"])},
    "disable-uploading-avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen von Avataren deaktivieren"])},
    "disable-unassigning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezassignierung deaktivieren"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung, wir konnten Ihre Zuweisung nicht bestätigen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code hinzufügen"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugewiesen"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf Bestätigung"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zuweisung wurde aufgehoben"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Report auswählen oder Suche starten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im vergangenen Monat"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Woche"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Jahr"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximalbetrag"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
    },
    "pooling-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling-Auszahlung"])},
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Unternehmen"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungewöhnlich hohe Zahlungen"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Mitarbeiter"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report speichern"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als ", _interpolate(_named("format")), " speichern"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Berichte"]), _normalize(["Berichte"])])},
    "teams-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "team-pooling-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Pooling Ergebnisse"])},
    "registration-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
    "distributed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt verteilt"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "account-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauszug"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "team-lead-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamleiter"])},
    "recipient-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEZAHLT"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSBEZAHLT"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Daten vorhanden"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team erstellen"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team löschen"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team bearbeiten"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mitarbeiter"]), _normalize(["Mitarbeiter"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Code der Gruppe"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher Ansprechpartner"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team mit Name ", _interpolate(_named("name")), " existiert bereits"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Team"]), _normalize(["Teams"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Name statt Benutzername auf Zahlungsseite anzeigen."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PayPal-Button (und Klarna-Button) deaktivieren, wenn das Trinkgeld weniger als ", _interpolate(_named("threshold")), " EUR beträgt."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zuweisung wurde aufgehoben"])},
    "hideOnSelectTeamPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstecken Sie ein Team auf der Seite Team auswählen zur Zahlung."])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaftlich"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen, Teams, oder QR Codes suchen"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["Users"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuell"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Teams"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "reset-cache": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Version is verfügbar."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hol dir die kostenlose App"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floßplatz 6, 04107 Leipzig, Deutschland"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". Alle Rechte vorbehalten."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anmeldelink wurde erfolgreich kopiert!"])}
  },
  "uploader": {
    "upload-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Datei hier ablegen oder"])},
    "click-to-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier klicken, um hochzuladen"])},
    "enter-manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder manuell eingeben"])}
  }
}